import React, {useEffect, useState} from "react";
import Article from "./Article";
import {getArticleList} from "../modules/api";
import {regexData} from "../tools/util";
import Layout from "../components/Layout";
import RigInfos from "../components/RigInfos";
import {Spin} from "antd";
import Head from "next/head";
import {useRouter} from "next/router";


export async function getServerSideProps(context) {
    let {current, pageSize} = context.query;
    if (!current) current=1;
    if (!pageSize) pageSize=10;
    const dat = await getArticleList({current, pageSize});
    const {currentPage, data, pages, total} = dat;
    console.log(context.query)
    const newData = regexData(data);
    return {
        props: {
            articleData: newData,
            pageOptions: {currentPage, pages, total}
        }
    }
}

export default function Home(props) {
    const router = useRouter()
    const {articleData, pageOptions: propsPageOptions} = props;

    const handlePage = async params => {
        router.push({ path: "/", query: params });
    }

  return (
      <Layout>
          <Head>
              <title>首页 | Parker的博客</title>
              <meta name="description" content="Parker的博客, 开发遇到的问题以及解决方案" />
              <meta name="keywords" content="前端, Vue, React, Css, JS, javascript, Java, Mysql, nginx, 服务器"/>
              <script async src="https://www.googletagmanager.com/gtag/js?id=G-39R0CDVPW5"></script>
              <script dangerouslySetInnerHTML={{
                  __html:`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-39R0CDVPW5');
                        `,
              }} />
          </Head>
          <div className="md:flex w-full">
              <div className="md:w-9/12 w-full">
                  <Article handlePage={handlePage} pageOptions={propsPageOptions} articleData={articleData} />
              </div>
              <div className="md:ml-4 md:w-3/12">
                  <RigInfos />
              </div>
          </div>
      </Layout>
  )
}
