import React, {useState} from 'react';
import {Image, Pagination} from 'antd';
import { useRouter } from 'next/router'
// import reactIcon from '../../public/img/classify/react.jpeg'
// import javaIcon from '../../public/img/classify/java.jpeg'
// import jsIcon from '../../public/img/classify/js.jpeg'
// import vueIcon from '../../public/img/classify/vue.jpeg'


const icons = {
    React: require('!!file-loader?name=[name].[ext]!../../public/img/classify/react.jpeg'),
    Java: require('!!file-loader?name=[name].[ext]!../../public/img/classify/java.jpeg'),
    JS: require('!!file-loader?name=[name].[ext]!../../public/img/classify/js.jpeg'),
    Vue: require('!!file-loader?name=[name].[ext]!../../public/img/classify/vue.jpeg'),
}

const defIcon = require('../../public/loading.svg');


export default function Article(props) {
    const router = useRouter()
    const [searchParams, setSearchParams] = useState({
        current: 1,
        pageSize: 10
    });
    const {pageOptions, articleData} = props;

    const jumpDetail = id => {
        router.push(`/ArticleDetail/${id}.html`);
    };



    const handlePage = (page, pageSize) => {
        const searchDef = {...searchParams};
        searchDef.current = page;
        searchDef.pageSize = pageSize;
        setSearchParams({...searchDef});
        if (props.handlePage) {
            props.handlePage(searchDef);
        }
    }

    const defLoading = (
        <Image src={defIcon.default.src} width={90} height={90} alt="def"/>
    )

    return (
        <div className="bg-white p-4 cursor-pointer rounded-md">
            <div className="divide-y divide-light-blue-400">
                {
                    articleData && articleData.map(item => (
                        <div onClick={() => jumpDetail(item.id)} className="max-w-md mx-auto rounded-sm overflow-hidden md:max-w-full" key={item.id}>
                            <div className="md:flex max-w-full relative my-3">
                                <div className="md:flex-shrink-0 items-center flex">
                                    <Image src={item.articleCover || (item.coverUrl || (icons[item.tagName] && icons[item.tagName].default.src))}
                                           preview={false}
                                           placeholder={defLoading}
                                           alt={item.articleTitle}
                                           width={90} />
                                </div>
                                <div className="p-3 max-w-full flex-1 flex items-center">
                                    <div className="">
                                        <h2>
                                            <a href="javascript:void(0)"
                                               dangerouslySetInnerHTML={{__html: item.articleTitle}}
                                               className="block mt-1 text-md leading-tight font-medium text-black hover:underline leading-6 text-base font-medium">
                                            </a>
                                        </h2>
                                        <div className="text-xs pt-2">
                                            分类：
                                            <span className="bg-gray-50 px-0.5 border border-black-200">
                                            {item.tagName}
                                            </span>
                                        </div>
                                        <div className="text-xs py-2">
                                            更新时间：
                                            <span className="pl-1 text-gray-400">
                                                {item.createTime.substring(0, 10)}
                                            </span>
                                        </div>
                                        <div className="text-gray-500 overflow-ellipsis overflow-hidden md:text-xs sm:text-base h-4">
                                            {item.articleContent}
                                        </div>
                                    </div>
                                    <span className="text-blue-400 text-xs absolute right-0 top-0 flex items-center">
                                </span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="pt-8">
                {
                    pageOptions &&
                    <Pagination
                        size="small"
                        total={pageOptions.total}
                        onChange={handlePage}
                        current={pageOptions.currentPage}
                        pageSize={searchParams.pageSize}
                        showSizeChanger
                        showQuickJumper />
                }
            </div>
        </div>
    )
}
